import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  GreyBody,
  HeaderH2,
  HeaderH3,
  MainContainer,
  ParaText,
  HeadingH4,
  UnorderedList,
  LinkStyle,
  ListItem,
} from '../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const ContentWithBody = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeaderH2 style={{ textAlign: 'left' }}>{children}</HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3 style={{ textAlign: 'left' }}>{children}</HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaText>{children}</ParaText>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <MainContainer className="text-center">
      <GreyBody>
        {sectionData?.header?.trim() && (
          <HeaderH2>{sectionData?.header}</HeaderH2>
        )}
        {sectionData?.subtext?.trim() && (
          <HeaderH3>{sectionData?.subtext}</HeaderH3>
        )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
      </GreyBody>
    </MainContainer>
  );
};
export default ContentWithBody;
